import joro from 'joro';
export const DS = {
  fontFamily: {
    default: "'Lexend Zetta', san-serif",
    alt: "'Lexend Zetta', san-serif",
  },
  fontSizes: {
    sm: 8,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 64,

  },
  gutters: {
    sm: 8,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 64,
  },
  colors: {
    purple: "#8D58FD",
    black: "#000000",
    white: "#ffffff",
    blue: "#58D5FD",
    green: "#83FD58",
  }
}

export const STYLES = new joro();





export function BaseStyles() {
  STYLES.add("baseStyles", `
    * {
      font-family: ${DS.fontFamily.default};
    }
    html,body {
      margin: 0;
      padding: 0;
      min-height: 100vh;
      color:  ${DS.colors.white};
      opacity: 1;
      font-family: ${DS.fontFamily.default};
    }
    body {
      background-color: ${DS.colors.black};
      background-size: cover;
      background-repeat:no-repeat;
      margin-bottom: ${DS.gutters.xxl*2}px;

    }
    h1 {
      font-size:${DS.gutters.xxl}px;
      letter-spacing: ${DS.gutters.xl}px;
      margin-left: ${DS.gutters.xxl}px;
      margin-bottom: 0px;
    }
    h1 + small {
      display:block;
      opacity: 0.5;
      margin-bottom: ${DS.gutters.xxl}px;
      margin-top: ${DS.gutters.lg}px;
    }

    h2 {
      font-size: ${DS.gutters.lg}px;
    }
    #cover-area {
      min-height: 478px;
      position:relative;
      z-index: 2;
      background-size:contain;
      background-position: center;
      background-repeat: no-repeat;
      min-width: 378px;
    }
    #cover-area img {
      height: auto;
      width: 100%;
    }
    #cover-area-cd {
      position:absolute;
      margin:0 auto;
      left:0;
      right:0;
      z-index:0;
      top: 10px;
      left: 40px;
      width: 410px;
    }
    input:focus,
    input {
      outline: none;
      border: 0;
      text-align: center;
      font-family: ${DS.fontFamily.default};
      font-size: ${DS.fontSizes.md/1.2}px;
      clear: both;
      width: 38%;
      color: #fff;
      display:block;
      margin: 0 auto;
      background: none;
      border-bottom: 2px solid #ffffff;
    }
    button {
      border: 0;
      outline: none;
      background-size: cover;
      padding: ${DS.gutters.md}px;
      cursor: pointer;
    }
    .wrapper {
      margin: 0 auto;
      margin-top: ${DS.gutters.xl}px;
      width: 480px;
    }
    .button-bar {
      width: 480px;
      margin: 0 auto;
    }
    .button-bar a {
      text-decoration: none;
      opacity: 0.5;
      width: 48%;
      display:inline-block;
      text-align:center;
    }
    .button-bar a.active {
      opacity: 1;
    }
    #cover-uploader {
      position: relative;
      width: 478px;
      overflow:hidden;
      min-height:478px;
      border:1px solid #fff;
    }
    #cover-uploader input {
      position: absolute;
      z-index: 4;
      width: 100%;
      opacity:0;
      height: 100%;
      right:0;
      left:0;
     
      margin: 0 auto;
      cursor: pointer;
    }
    .personal-cover-overlay,
    .personal-cover {
      width: ${260}px;
      max-height: ${260}px;
      left:52px;
      top:64px;
      position:absolute;
      z-index: 2;
      border-top-right-radius: 8px;
    }
    .personal-cover-overlay {
      z-index: 3;
      opacity: 0.5;
    }
    .emoji-picker__wrapper {
      z-index: 99;
    }
    #key-wrapper {
      width: 480px;
      margin: 0 auto;
     box-sizing:border-box;
     border:1px solid #fff;
    }
    div#key-wrapper * {
      display:inline-block;
      font-size: ${DS.fontSizes.xl}px;
   }
   #key-wrapper span {
     height: 48px;
     line-height: 48px;
     font-size: ${DS.fontSizes.md}px;
     float:left;
     padding-left: 4px;
     overflow:hidden;
     text-align:center;
   }
   div#key-wrapper input,
   div#key-wrapper button {
     background: #000;
     margin:0;
     display:inline-block;
     padding:0;
     height: 48px;
     border: 0px;
   }
   div#key-wrapper input {
     width: 295px;

   }
   div#key-wrapper button {
     width: 48px;
     border-left: 1px solid #fff;
   }
    @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
    }
    textarea {
        width: 480px;
        box-sizing:border-box;
        clear:both;
        display:block;
        margin: 0 auto;
        background: #000;
        color: #fff;
        padding-top: ${DS.gutters.md}px;
        border:1px solid #fff;
        text-align:center;
        min-height: 150px;
        font-size: ${DS.fontSizes.md}px;
      }
      div#track-message {
        position: absolute;
        top: 0;
        z-index: 9;
        left: 0;
        margin: auto;
        right: 0;
        bottom: 0;
        opacity:0;
        padding: 32px;
        background: rgba(0,0,0,0.8);
        transition: 0.3s ease all;
    }
    #track-message.show {
      opacity:1;
    }
    span.helper {
      opacity: 0.2;
      width: 240px;
      height: 150px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      margin: auto;
      right: 0;
      bottom: 0;
  }
    div#crypto-img {
      height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 480px) {
  h1 {letter-spacing: 0px;font-size: 7vw;margin: 16px 0;width: 100%;text-align: center;}
.button-bar,
textarea,
#key-wrapper,
#cover-uploader,
#cover-area,
.wrapper {
  width: 100vw;
  min-width: 100vw;
  border-right : 0px;
  border-left : 0px;
  font-size:0.9em;
}
span.helper,
div#key-wrapper input {
  width: 56vw;
}

}
    `)
  }

  // textarea,
  // button {
  //   display: block;
  //   clear:both;
  //   margin: ${DS.gutters.sm}px auto ${DS.gutters.sm}px auto;
  //   ${buttonStyle()}
  // }

