import { reducer } from 'obake.js';

export const defaultState = {
  youtube: "",
  track: "",
  emojiEncode: "🌶️💭",
  emojiDecode: "🌶️💭",
  trackCover: null,
  currentPage: { name: 'HOME' },
  image: null
 }


 export const activePage = {
   "HOME": "/",
 }
export const reducers = {
  updateCurrentPage: reducer((state, value: string) => {
    state.currentPage = { name: value  };
  }),
  updateMessage: reducer((state, value: string) => {
    state.message = value;
  }),
  addEmojiKey: reducer((state, value: string) => {
    state.emojiEncode = state.emojiEncode + value;
  }),
  updateDecode: reducer((state, value: string) => {
    state.emojiDecode = value;
  }),
  updateEncode: reducer((state, value: string) => {
    state.emojiEncode = value;
  }),
  updateTrack: reducer((state, value: string) => {
    state.track = value;
  }),
  updateTrackCover: reducer((state, value: string) => {
    state.trackCover = value;
  }),
  updateImage: reducer((state, value: string) => {
    state.image = value;
  }),

}
