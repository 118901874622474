import * as actions from "./actions";
import html from "nanohtml";
import { EmojiButton } from "@joeattardi/emoji-button";
import { state } from "index";
const picker = new EmojiButton({
  theme: "dark",
});
picker.on("emoji", (selection) => {
  (document.querySelector("#key") as any).value =
    (document.querySelector("#key") as any).value + selection.emoji;
  state._update("updateDecode", (document.querySelector("#key") as any).value);
  state._update("updateEncode", (document.querySelector("#key") as any).value);
  // `selection` object has an `emoji` property
  // containing the selected emoji
});

export function AppRoot(state) {
  return html`
    <div id="app">
      <div class="page">${routing(state)}</div>
    </div>
  `;
}

export function imageHandler(state) {
  return html`
    <div id="cover-uploader">
      <input
        type="file"
        id="encode-image"
        class="encoder"
        name="encode-image"
        accept="image/*;capture=camera"
        onchange=${(e) => actions.coverImage(e)}
      />
      <div id="cover-area">
        <img src="${state.image}" />
        <span class="helper">🩻 Drag or click to add an image</span>
      </div>
    </div>
  `;
}

export function navbar(state) {
  switch (state.currentPage.name) {
    case "PLAYER":
      return html`
        <div class="button-bar">
          <a  href="/">Encode</a>
          <a class="active" href="/player">[Decode]</a>
        </div>
      `;
    default:
      return html`
        <div class="button-bar">
          <a class="active" href="/">[Encode]</a>
          <a href="/player">Decode</a>
        </div>
      `;
  }
}

export function playerHandler(state) {
  return html`
  <div id="key-wrapper">
  <span>password:</span>
      <input
        id="key"
        onchange="${(e) => actions.updateDecode(e)}"
        value="${state.emojiDecode}"
      />
      <button
        onclick="${(e) => {
          picker.togglePicker(e.target);
        }}"
      >
        🔥
      </button>
    </div>
    <div id="cover-uploader">
      <input
        type="file"
        id="decode-image"
        class="decoder"
        name="decode-image"
        accept="image/*;capture=camera"
        onchange=${(e) => actions.updateTrack(e)}
      />
      <div id="cover-area">
        <div id="track-message" class="${
          state.track.length > 0 ? "show" : ""
        }">${state.track}</div>
        <div id="crypto-img" style="background-image:url(${state.trackCover})" /></div>
        <span class="helper">🩻 Drag or click to decode an image</span>
      </div>
    </div>
    
    <button onclick=${(e) => actions.decodeImage(e)}>get message out of an image</button>

  `;
}

export function routing(state) {
  switch (state.currentPage.name) {
    case "HOME":
      return html`
        <h1>HOTAKES 🌶️💭</h1>
        <small>encode secret messages for posting on social media</small>
       ${navbar(state)}
        <div class="wrapper">${imageHandler(state)}</div>
        <div id="key-wrapper">
          <span>password:</span>
          <input
            id="key"
            onchange="${(e) => actions.updateEncode(e)}"
            value="${state.emojiEncode}"
          />
          <button
            onclick="${(e) => {
              picker.togglePicker(e.target);
            }}"
          >
            🔥
          </button>
        </div>
        <textarea
          onchange=${(e) => actions.newYoutube(e)}
          placeholder="type your message to encode "
        >${state.message}</textarea>
        <button onclick="${actions.createCover}">store your message into the image</button>
      `;
    case "PLAYER":
      return html`
   <h1>HOTAKES 🌶️💭</h1>
    ${navbar(state)}
    <div class="wrapper">
    ${playerHandler(state)}
    </div>
  </div>
`;
    default:
      return html` <h1>404 cover</h1> `;
  }
}
